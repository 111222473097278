import { useState, useReducer, useEffect } from 'react';
import { useRoutes } from 'react-router-dom';
import routes from '@/router';
import './App.less';
import { Button, Dropdown } from 'antd';
import { useLocation } from 'react-router-dom';
import { toggleLocale, localeSelector } from '@/store/slice/common';
import { useDispatch, useSelector } from 'react-redux';
import { IntlProvider } from 'react-intl';
import messages from './lang';
import Svg from './components/Svg';
import MenuList from './components/MenuList';

// 切换链下拉选项
const chainList = [
  {
    key: 'item-1',
    label: '菜单1'
  },
  {
    key: 'item-2',
    label: '菜单2'
  }
];

const menuInitialState = { isTouch: false, collapsed: true };

const menuReducer = (state, action) => {
  switch (action.type) {
    case 'toTouchScreen':
      return { isTouch: true, collapsed: true };
    case 'toPcScreen':
      return { isTouch: false, collapsed: false };
    case 'toggleCollapsed':
      return { ...state, collapsed: !state.collapsed };
    default:
      return state;
  }
};

export default function App() {
  const elements = useRoutes(routes);
  const location = useLocation();
  const dispatch = useDispatch();
  const locale = useSelector(localeSelector);

  const [inited, setInited] = useState(false);
  const [menuState, menuDispatch] = useReducer(menuReducer, menuInitialState);

  useEffect(() => {
    if (!inited) {
      // 清除pre-loading动画
      const preLoadingEle = document.getElementsByClassName('pre-loading')[0];
      if (preLoadingEle) preLoadingEle.parentNode.removeChild(preLoadingEle);

      judgeWindowWidth(true);
      setInited(true);
    }

    // 事件监听注册 每次更新需重新注册 否则形成menuState的闭包
    window.addEventListener('resize', judgeWindowWidth);
    return () => {
      window.removeEventListener('resize', judgeWindowWidth);
    };
  }, [menuState]);

  // 判断设备尺寸
  const judgeWindowWidth = () => {
    const width = window.innerWidth;
    if (width <= 768 && (!inited || !menuState.isTouch)) {
      menuDispatch({ type: 'toTouchScreen' });
    } else if (width > 768 && (!inited || menuState.isTouch)) {
      menuDispatch({ type: 'toPcScreen' });
    }
  };

  return (
    <IntlProvider key={locale} locale={locale} messages={messages[locale]}>
      {inited && (
        <div className="app w-100 h-100 flex-item bg-white">
          <aside className="flex-label h-100">
            <MenuList {...menuState} toggleMenu={() => menuDispatch({ type: 'toggleCollapsed' })} />
          </aside>
          <article className={`flex-content flex-vertical ${menuState.isTouch ? 'is-touch' : ''}`}>
            <header className={`flex-header d-flex justify-content-between align-items-center px-3 ${menuState.isTouch ? 'is-touch' : ''}`}>
              <div className="flex-label flex-center-center">
                <div onClick={() => menuDispatch({ type: 'toggleCollapsed' })} className="cursor-pointer flex-center-center">
                  <Svg name={menuState.collapsed ? 'unfold' : 'fold'} size="1.5" fill="#1a1d3e" hover="primary" />
                </div>
                <div className="text-md ml-1">{location.pathname.slice(1)}</div>
              </div>
              <div className="d-flex align-items-center">
                <Button shape="round" className="flex-center-center">
                  <Svg name="wallet" size="1.2" fill="#ffcd36" />
                  <div className="ml-1">Link Wallet</div>
                </Button>
                <Dropdown menu={{ items: chainList }} placement="bottom" arrow={{ pointAtCenter: true }}>
                  <Button shape="round" className="flex-center-center">
                    <Svg name="chain" size="1.2" fill="#2fa73f" />
                    {!menuState.isTouch && <div className="ml-1">Toggle Chain</div>}
                  </Button>
                </Dropdown>
                <Button shape="round" className="flex-center-center" onClick={() => dispatch(toggleLocale())}>
                  <Svg name={locale === 'en' ? 'en' : 'zh'} size="1.2" fill="#5c70f0" />
                </Button>
              </div>
            </header>
            <main className="flex-content p-3">{elements}</main>
          </article>
        </div>
      )}
    </IntlProvider>
  );
}
