import { createSlice } from '@reduxjs/toolkit';

const commonSlice = createSlice({
  name: 'common',
  initialState: {
    loading: false,
    locale: localStorage.getItem('locale') || 'zh'
  },
  reducers: {
    toggleLoading: (state) => {
      state.loading = !state.loading;
    },
    toggleLocale: (state) => {
      const locale = state.locale === 'zh' ? 'en' : 'zh';
      state.locale = locale;
      localStorage.setItem('locale', locale);
    }
  }
});

export const localeSelector = (state) => state.common.locale;
export const { toggleLoading, toggleLocale } = commonSlice.actions;

export default commonSlice.reducer;
