import { Row, Col } from 'antd';
import { FormattedMessage } from 'react-intl';

export default function Index() {
  return (
    <div>
      <div>测试</div>
      <div className="mb-3"><FormattedMessage id="home.title" /></div>
      <Row gutter={16}>
        <Col xs={24} sm={12} md={8} lg={6} xl={4} className="mb-3">
          <div className="py-6 bg-white round-md"></div>
        </Col>
        <Col xs={24} sm={12} md={8} lg={6} xl={4} className="mb-3">
          <div className="py-6 bg-white round-md"></div>
        </Col>
        <Col xs={24} sm={12} md={8} lg={6} xl={4} className="mb-3">
          <div className="py-6 bg-white round-md"></div>
        </Col>
        <Col xs={24} sm={12} md={8} lg={6} xl={4} className="mb-3">
          <div className="py-6 bg-white round-md"></div>
        </Col>
        <Col xs={24} sm={12} md={8} lg={6} xl={4} className="mb-3">
          <div className="py-6 bg-white round-md"></div>
        </Col>
        <Col xs={24} sm={12} md={8} lg={6} xl={4} className="mb-3">
          <div className="py-6 bg-white round-md"></div>
        </Col>
      </Row>
      <Row gutter={16}>
        <Col xs={24} md={12} xl={8} className="mb-3">
          <div className="py-6 bg-white round-md"></div>
        </Col>
        <Col xs={24} md={12} xl={8} className="mb-3">
          <div className="py-6 bg-white round-md"></div>
        </Col>
        <Col xs={24} md={12} xl={8} className="mb-3">
          <div className="py-6 bg-white round-md"></div>
        </Col>
      </Row>
      <Row gutter={16}>
        <Col xs={24} md={12} className="mb-3">
          <div className="py-6 bg-white round-md">
            <div className="py-6"></div>
          </div>
        </Col>
        <Col xs={24} md={12} className="mb-3">
          <div className="py-6 bg-white round-md">
            <div className="py-6"></div>
          </div>
        </Col>
      </Row>
    </div>
  );
}
