import './index.less';
import { Menu } from 'antd';
import { MacCommandOutlined, ProjectOutlined } from '@ant-design/icons';
import { FormattedMessage } from 'react-intl';
import { useNavigate } from 'react-router-dom';

const menuList = [
  {
    label: <FormattedMessage id="menu.home" />,
    key: 'Home',
    icon: <MacCommandOutlined />
  },
  {
    label: 'Launchpads',
    key: 'Launchpads',
    icon: <ProjectOutlined />,
    children: [
      {
        label: 'Create Launchpad',
        key: 'LaunchpadCreate'
      },
      {
        label: 'Launchpad List',
        key: 'LaunchpadList'
      }
    ]
  },
  {
    label: 'NTF',
    key: 'NTF',
    children: [
      {
        label: 'Live Mint',
        key: 'LiveMint'
      },
      {
        label: 'MultiSender',
        key: 'MultiSender'
      },
      {
        label: 'NTF Approval',
        key: 'NTFApproval'
      },
      {
        label: 'NTF Collection',
        key: 'NTFCollection'
      }
    ]
  },
  {
    label: 'Create Token',
    key: 'CreateToken',
    children: [
      {
        label: 'Dividend Coin',
        key: 'DividendCoin'
      },
      {
        label: 'ETH',
        key: 'TokenETH'
      },
      {
        label: 'ETHW',
        key: 'TokenETHW'
      },
      {
        label: 'ETHF',
        key: 'TokenETHF'
      },
      {
        label: 'BSC',
        key: 'TokenBSC'
      },
      {
        label: 'HECO',
        key: 'TokenHECO'
      }
    ]
  },
  {
    label: 'Create Mining',
    key: 'CreateMining'
  },
  {
    label: 'Token MultiSender',
    key: 'TokenMultiSender',
    children: [
      {
        label: 'ETH',
        key: 'TokenSenderETH'
      },
      {
        label: 'ETHW',
        key: 'TokenSenderETHW'
      },
      {
        label: 'BSC',
        key: 'TokenSenderBSC'
      }
    ]
  },
  {
    label: 'Batch Check Balance',
    key: 'BatchCheckBalance',
    children: [
      {
        label: 'ETH',
        key: 'BatchCheckBalanceETH'
      },
      {
        label: 'ETHW',
        key: 'BatchCheckBalanceETHW'
      },
      {
        label: 'BSC',
        key: 'BatchCheckBalanceBSC'
      }
    ]
  },
  {
    label: 'Create Wallet',
    key: 'CreateWallet',
    children: [
      {
        label: 'ETH',
        key: 'CreateWalletETH'
      },
      {
        label: 'ETHW',
        key: 'CreateWalletETHW'
      },
      {
        label: 'BSC',
        key: 'CreateWalletBSC'
      }
    ]
  }
];

export default function Index(props) {
  const navigate = useNavigate();

  const { isTouch, collapsed } = props;

  // 路由切换
  const onClickMenu = (menu) => {
    navigate(menu.key);
  };

  return (
    <>
      <div className={`menu-list h-100 bg-white flex-vertical ${collapsed ? 'fold' : ''}`}>
        <div className={`logo-box flex-header flex-item align-items-center overflow-hidden ${(!isTouch && collapsed) ? 'fold' : ''}`}>
          <div className="logo flex-label flex-center-center">
            <img src={require('@/assets/img/logo.png')} />
          </div>
          <div className="flex-label flex-vertical text-center text-bold">
            <div className="text-sm">WEB3 触须</div>
            <div className="text-min">WEB3 TENTACLE.COM</div>
          </div>
        </div>
        <div className="flex-content">
          <Menu defaultSelectedKeys={['Home']} mode="inline" inlineCollapsed={!isTouch && collapsed} onClick={onClickMenu} items={menuList} />
        </div>
      </div>
      {
        isTouch && !collapsed ? <div className="menu-bg w-100 h-100" onClick={props.toggleMenu} /> : ''
      }
    </>
  );
}