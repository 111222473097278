import Home from '@/views/Home';

const routes = [
  {
    path: '/',
    element: <Home />
  }
];

export default routes;
